import React from "react"

const Title = ({ title }) => (
  <div className="row">
    <div className="col mb-3">
      <h1 className="display-4 text-capitalize font-weight-bold">{title}</h1>
    </div>
  </div>
)

export default Title
