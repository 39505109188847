import React from "react"
import BackgroundImage from "gatsby-background-image"

const Background = ({ img, styleClass, children }) => {
  return (
    <BackgroundImage className={styleClass} fluid={img}>
      {children}
    </BackgroundImage>
  )
}

Background.defaultProps = {
  title: "default title",
  styleClass: "default-background",
}

export default Background
