import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/globals/Background"
import Info from "../components/Info"
import { FaChevronRight } from "react-icons/fa"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={["rails engineer", "web developer", "project management"]}
    />
    <Background
      img={data.img.childImageSharp.fluid}
      styleClass="default-background"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="title text-black text-uppercase text-left display-4 font-weight-bold">
              Web Developer
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-left px-10">
            <p className="lead">
              I am web developer & programmer living in Manila, Philippines. I
              make rich web applications, usually with{" "}
              <a
                className="font-weight-bold text-black"
                href="https://reactjs.org/"
              >
                ReactJS
              </a>{" "}
              and{" "}
              <a
                className="font-weight-bold text-black"
                href="https://rubyonrails.org/"
              >
                Ruby on Rails
              </a>
            </p>
            <Link to="/about">
              <button className="btn btn-black">
                <span className="text-uppercase">Learn More...</span>
                <FaChevronRight />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Background>
    <Info title="Skillset">
      <div className="row">
        <div className="col-8 col-sm-8">
          <p className="lead text-muted mb-5">
            I am primarily use{" "}
            <a
              className="font-weight-bold text-black"
              href="https://rubyonrails.org/"
            >
              Ruby on Rails
            </a>{" "}
            and{" "}
            <a
              className="font-weight-bold text-black"
              href="https://reactjs.org/"
            >
              ReactJS
            </a>
            . I have extensive experience with both Back-end and Front-end
            development.
          </p>
          <Link to="/about">
            <button className="btn text-uppercase btn-yellow">
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </Info>
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: { eq: "default-background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
