import React from "react"
import Title from "./globals/Title"
const Info = ({ title, children }) => (
  <section className="py-5">
    <div className="container">
      <Title title={title} />
      {children}
    </div>
  </section>
)

export default Info
